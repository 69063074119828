import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OvalPrimaryButton from "./Buttons/OvalPrimaryButton";
import ItemCard from "./ItemCard"; // Assuming ItemCard is imported correctly
import PageHeader from "./PageHeader";
import OptOutOnceImg from "./images/optOutOnce.svg";

function OptOut() {
  const [optOutData, setOptOutData] = useState("");
  const [showFinalBtns, setShowFinalBtns] = useState(false);
  const navigate = useNavigate();

  const optOutHandle = () => {
    localStorage.removeItem("ironBtnOptOut");
    sessionStorage.removeItem("ironBtnOptOut");
    if (optOutData === "optOutAlways") {
      window.parent.postMessage({ type: 'optOutAlways' }, '*');
    } else if (optOutData === "optOutOnce") {
      window.parent.postMessage({ type: 'optOutOnce' }, '*');
    }
  };

  const optOutChoice = (choice) => {
    setOptOutData(choice);
    setShowFinalBtns(true);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div>
      <PageHeader title={"הסרה"} />
      <div className="content">
        <ItemCard
          content={<div className="contentTitle">לתמיד</div>}
          buttonTitle="בחירה"
          onClick={() => optOutChoice("optOutAlways")}
          icon={
            <div className="itemIconContainer">
              <img src="/optOutIcon.svg" alt="optOutIcon" />
            </div>
          }
        />

        <ItemCard
         content={<div className="contentTitle">רק הפעם</div>} 
          buttonTitle="בחירה"
          onClick={() => optOutChoice("optOutOnce")}
          icon={
            <div className="itemIconContainer">
              <img src={OptOutOnceImg} alt="optOutIcon" />
            </div>
          }
        />
      </div>
      {showFinalBtns && (
        <div>
          <Typography className="subTitle">
            בטוח שברצונך להסיר את הכפתור?
          </Typography>
          <div className="content">
            <OvalPrimaryButton text="כן, בטוח" className="red small" onClick={optOutHandle} />
            <OvalPrimaryButton text="לא, אני רוצה להשאיר" className="small" onClick={handleCancel} />
          </div>
        </div>
      )}
    </div>
  );
}

export default OptOut;
