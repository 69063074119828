import { Grid, Typography } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ContentComponent.css";
import { IronTheme } from "./IronTheme";
import ItemCard from "./ItemCard";
import Searchbar from './Searchbar'; // Make sure the path is correct
import PageHeader from './PageHeader';

function CategoriesList() {
  const location = useLocation();
  const theme = useTheme();
  const categoryData = location.state;
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(categoryData.data || []);

  useEffect(() => {
    filterData(searchTerm);
  }, [categoryData.data, searchTerm]);

  const filterData = (term) => {
    if (!term) {
      setFilteredData(categoryData.data);
    } else {
      const filtered = categoryData.data.filter(item =>
        item.title.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const showInitiativeInfo = async (initiative) => {
    navigate(`initiative/${initiative.title}`, { state: { intiativeInfo: initiative, category: categoryData.category.replace('"', "") } });
  };

  if (!categoryData || !categoryData.data) {
    return <div>Loading or no data available...</div>;
  }

  return (
    <div>
      <ThemeProvider theme={IronTheme}>
        <PageHeader title={categoryData.category.replace('"', "")} />
        <Searchbar onSearch={handleSearch} />
        <Grid container spacing={2} marginTop={"3px"} className="content" justifyContent="center">
          {filteredData.map((item, index) => (
            <Grid item key={"category_list_" + index} xs={10}>
              <ItemCard
              sx={{height:125}}
                buttonTitle="מידע נוסף"
                onClick={() => showInitiativeInfo(item)}
                content={
                  <div style={{display:"flex",height:"100%",alignItems:"center",justifyContent:"center"}}>
                    <p style={{ "font": "normal normal bold 18px/18px Assistant", textAlign: "center", color: theme.palette.secondary.main,margin:0,inlineSize:200,overflowWrap:"break-word" }}>{item.title}</p>
                  </div>
                }
              />
            </Grid>
          ))}
          {filteredData.length === 0 && (
            <Typography style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
      לא נמצאו תוצאות עבור החיפוש
            </Typography>
          )}
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default CategoriesList;