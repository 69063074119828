import BackButton from './Buttons/BackButton';

export default function PageHeader({title}) {
  return (
      <div
          style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative"
          }}
      >
          <div style={{ position: "absolute", right: "20px",zIndex: 5 }}>
              <BackButton />
          </div>
          <div style={{ flexGrow: 1 }}>
              <div className="contentTitle">
                  {title}
              </div>
          </div>
      </div>
  );
}
