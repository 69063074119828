import { useCallback, useRef } from 'react';

export default function useScrollToRef()  {
    const ref = useRef(null);
    const scrollToRef = useCallback(() => {
        if (ref.current) {
            ref.current.scrollTo({ behavior: 'instant', top: 0, left: 0 });
        }
    }, []);
    return [ref, scrollToRef];
}
