import React, { useState } from 'react';
import './OvalPrimaryButton.css'; // Make sure to create this CSS file

function OvalPrimaryButton({ text, onClick, className }) {
  const [isPressed, setIsPressed] = useState(false);
  
  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  return (
      <button
      className={`ovalPrimaryButton ${className} ${isPressed ? 'pressed' : ''}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // In case the mouse leaves the button while pressed
      onClick={onClick}
      >
        {text}
      </button>
  );
}

export default OvalPrimaryButton;
