import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import shareIcon from '../images/shareIcon.svg';

const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    width: '70px', // Initial width of the button
    marginLeft: 'auto', // Push the button to the right
    overflow: 'hidden', // Hide the overflowing elements
    transition: 'width 0.8s ease-in-out',
    backgroundColor: "#eef2fe",
    borderRadius: "50px",
    '&:hover': {
        width:190,
        backgroundColor: "#dee5fe",
    }
});

const SocialMediaIcons = styled('div')({
    display: 'flex',
});

const ShareButton = () => {
    const [open, setOpen] = useState(false);
    const getMessage = () => {
        const url = window.location.href + "/external";
        const text = "היי, ראיתי את היוזמה הזאת וחשבתי שזה יעניין אותך: ";
        return encodeURIComponent(text) + encodeURIComponent(url);
    }

    const shareWA = () => {
        const waApiLink = "https://api.whatsapp.com/send?text="
        window.open(`${waApiLink}${getMessage()}`);
    }

    const shareTelegram = () => {
        const telegramApiLink = "https://t.me/share/url?text="
        window.open(`${telegramApiLink}${getMessage()}`);
    }

    const copyLink = () => {
        const url = window.location.href + "/external";
        navigator.clipboard.writeText(url);
        setOpen(true);
    }

    return (
        <Tooltip title="שיתוף">
            <Wrapper>
                <SocialMediaIcons>
                    <IconButton style={{ maxHeight: "15px" }} color='secondary' on onClick={copyLink}>
                        <ContentCopyIcon fontSize='small' />
                    </IconButton>
                    <IconButton style={{ maxHeight: "15px" }} color='secondary' onClick={shareTelegram}>
                        <TelegramIcon fontSize='small' />
                    </IconButton>
                    <IconButton style={{ maxHeight: "15px" }} color='secondary' >
                        <WhatsAppIcon fontSize='small' onClick={shareWA} />
                    </IconButton>
                </SocialMediaIcons>
                <span style={{ display: "flex", fontSize: "14px",padding:"3px 6px",gap:"5px"}}>
                    <img src={shareIcon} alt="share" style={{ maxHeight: "20px", marginRight: "5px",alignItems:"center" }} />
                    שיתוף
                </span>
            </Wrapper>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal:"center" }}
                onClose={() => setOpen(false)}
                message="הקישור הועתק" />
        </Tooltip>
    );
};

export default ShareButton;
