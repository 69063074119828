import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import React, { useState } from 'react';
import OvalPrimaryButton from "./Buttons/OvalPrimaryButton";
import PageHeader from './PageHeader';

const shortMessageError = 'התיאור קצר מידי';

const ContactForm = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailFieldError, setEmailError] = useState('');
    const [messageFieldError, setMessageError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setSubmitted(false);
        if (name === 'email') {
            if (value && !validateEmail(value)) {
                setEmailError('אימייל לא תקין');
            } else {
                setEmailError('');
            }
        } else if (name === 'message') {
            if (!validateMessage(value)) {
                setMessageError(shortMessageError);
            } else {
                setMessageError('');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setEmailError('');
            try{
                setIsLoading(true);
                await axios.post(`${process.env.REACT_APP_API_URL}/reportIssue`, JSON.stringify({ email, message }), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setIsLoading(false);
            }
            catch(e){
                setError('שגיאה בשליחת הטופס');
                setSubmitted(false);
                setIsLoading(false);
                return;
            }
            setSubmitted(true);
            setError('');
            // Reset form fields
            setEmail('');
            setMessage('');
        } else {
            !validateMessage(message) && setMessageError(shortMessageError);
            !validateEmail(email) && setEmailError('אימייל לא תקין');
            setSubmitted(false);
        }
    };

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validateMessage = (message) => {
        return message && message.length > 3;
    };

    const validateForm = () => {
        const isEmailValid = email ? validateEmail(email) : true;
        return isEmailValid && validateMessage(message);

    };

    const getMessage = () => {
        if (submitted) {
            return 'הטופס שוגר בהצלחה';
        } else if (error) {
            return error;
        } else {
            return '';
        }
    }

    return (
        <div>
            <PageHeader title={"דווח על תקלה"} />
            <div className="">
                <form onSubmit={handleSubmit} style={{padding:"16px",display:"flex",flexDirection:"column"}}>
                    <TextField
                        name="email"
                        label="אימייל"
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={handleBlur}
                        error={!!emailFieldError}
                        helperText={emailFieldError}
                        placeholder='leon@gmail.com'
                        InputProps={{ sx: { borderRadius: 4 } }}
                    />
                    <br />
                    <TextField
                        name="message"
                        label="תיאור"
                        value={message}
                        onChange={handleMessageChange}
                        onBlur={handleBlur}
                        error={!!messageFieldError}
                        helperText={messageFieldError}
                        multiline
                        rows={4}
                        InputProps={{ sx: { borderRadius: 4 } }}
                    />
                    <br />
                    <OvalPrimaryButton text="2,3...שגר" onClick={handleSubmit} />
                </form>
                <div style={{display:"flex"}}>
                    {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
                        <CircularProgress />
                    </div>}
                    {!isLoading && <Typography variant="body1" color={error ? "error" : submitted ? "green" : ""} style={{ margin: "0 auto", display: "flex", gap: "6px" }}>
                        {submitted && <CheckCircleIcon style={{ color: "green" }} />}
                        {error && <ErrorOutlineIcon style={{ color: "red" }} />}
                        {getMessage()}
                    </Typography>}
                </div>
            </div>

        </div>
    );
};

export default ContactForm;
