import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import PrimaryButton from "./Buttons/PrimaryButton";
import { IronTheme } from "./IronTheme";
import "./ItemCard.css";

export default function ItemCard({ buttonTitle, content, icon, onClick,sx, }) {
  return (
    <ThemeProvider theme={IronTheme}>
      <button className="cardButton" onClick={onClick}>
        <Card
          sx={{...{
            maxWidth: "100%",
            height: 200,
            maxHeight: "100%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)", // Adjust the shadow here
            display: "flex",
            flexDirection: "column",
            borderRadius: "20px",
          },...sx}}
        >
          <CardContent sx={{ flexGrow: 1,overflowY:'auto' }}>
            {icon && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                {icon}
              </div>
            )}
            {typeof content === "string" ? (
              <Typography variant="body2">{content}</Typography>
            ) : (
              content
            )}
          </CardContent>
          <div style={{ width: "100%" }}>
            <PrimaryButton
              className="primaryButtonAtBottom"
              text={buttonTitle}
            />
          </div>
        </Card>
      </button>
    </ThemeProvider>
  );
}
