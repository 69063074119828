import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import './ContentComponent.css';
import PageCard from './PageCard';
import PageHeader from './PageHeader';
import { ReactComponent as HelpersLogo } from "./images/helpersLogo.svg";
import { ReactComponent as PartnersLogo } from "./images/partnersLogo.svg";

const ActionButton = styled(Button)(({ theme }) => ({
  color: "unset",
  borderRadius: "25px",
  border:"0.5px solid #00AEEF",
  width: "100%",
  position: "relative",
  fontSize: "unset",
  "&:hover": {
    backgroundColor: "#00AEEF",
    color: "white",
  }
}));


const contributors = [
  {
    name: "חן ברקת",
    linkedin: "https://www.linkedin.com/in/chenba"
  },
  {
    name: "יהודה ספיר",
    linkedin: "https://www.linkedin.com/in/yehuda-sapir-7a7a6423"
  },
  {
    name: "טל נגר",
    linkedin: "https://www.linkedin.com/in/tal-nagar"
  }, {
    name: "לאון קולודנר",
    linkedin: "https://www.linkedin.com/in/leon-kol"
  }, {
    name: "תומר מלניק",
    linkedin: "https://www.linkedin.com/in/tomermelnik"
  }];

function AboutUs() {
  return (
    <div>
      <PageHeader title={"קצת על היוזמה"} />
      <PageCard title={"כפתור יחד ננצח"}>
        <div className='text'>
          <h2 gutterBottom className='textTitle'>
            הסיפור שלנו
          </h2>
          <p variant="body1" gutterBottom >
              מיזם “כפתור יחד ננצח” (Iron Button) הוא וידג’ט מאובטח שמנגיש באופן פשוט וקל את כל המידע, ההתנדבויות והיוזמות בכפתור אחד מאובטח ופשוט להתקנה בכל אפליקציה ואתר אינטרנט בעולם. 
          </p>
          <p variant="body1" gutterBottom >
            המיזם נולד תוך כדי מלחמת “חרבות הברזל”. זיהינו שמצד אחד יש המון יוזמות מדהימות שצמחו בן לילה בצורה בתנדבותית ואזרחית לחלוטין ומספקות שירותים אזרחיים חשובים ומדהימים. מצד שני, ריבוי היוזמות, עודף האינפורמציה והעובדה שהן צמחו מהיום למחר, יצרה מציאות בה אנשים כלל לא מכירים ולא נחשפים ליוזמות אלו. 
          </p>
          <p variant="body1" gutterBottom >
            הכפתור, הניתן להטמעה בצורה פשוטה וקלה בכל אפליקציה ואתר אינטרנט בעולם, מאפשר בדיוק את החשיפה הרחבה ליוזמות מדהימות אלו. 
            אתרים הפועלים כבר תקופה ארוכה וזוכים לחשיפה גבוהה, מאפשרים נגישות גבוהה ותמידית לקהלים.
          </p>
          <h2 gutterBottom className='textTitle'>
             מי עומד מאחורי היוזמה       
          </h2>
          <div>
            מיזם “כפתור יחד ננצח” (Iron Button) הוא מיזם אזרחי התנדבותי לחלוטין ומתוך רצון אמיתי לסייע לכולנו בתקופה מורכבת זו.
          </div>
          <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', alignItems: 'center',marginBlockStart:"15px",gap:"10px" }} >
            {contributors.map((contributor) => {
              return (
                <ActionButton index={contributor.name} className='text'> 
                  <div>{contributor.name}</div>
                  {contributor.linkedin && <a href={contributor.linkedin} style={{ display: "flex", position: "absolute",left:"7px" }} target="_blank" rel="noreferrer">
                      <LinkedInIcon fontSize='large' sx={{ color: "#0077b5" }} />
                    </a>}
                </ActionButton>
              )
            })}
          </div>
        </div>
        <h2 gutterBottom className='textTitle' style={{marginTop:30}}>תודה לשותפים לדרך</h2>
        <PartnersLogo />
        <div className='text' style={{marginBlock:15}}>
          על הסיוע בארגון והרמת היוזמה
        </div>
        <HelpersLogo />
        <div style={{ marginTop: "15px" }}>
          אבטחת הכפתור נעשתה בסיוע של חברת ITway מבית NessCloud ובאמצעות פתרונות Cyber & Security של חברת Akamai
        </div>
        <h2 gutterBottom className='textTitle' style={{ marginTop: 30 }}>הצטרפו אלינו</h2>
        <div className='text' style={{marginBottom:15}}>
          בכפתור יחד ננצח האתר זוכה להנגיש לקהלים שלו מידע חשוב ולעזור לו לצלוח את התקופה המורכבת הזו דרך צריכה של מידע איכותי ואמין, וחשיפה לשירותים חשובים אנו מזמינים אתכם להצטרף אלינו ולהטמיע את הכפתור גם באתר שלכם!
        </div>
          <a href="https://ironbutton.org/" target="_blank" rel="noreferrer" style={{color:"unset"}}>
            <ActionButton>לקוד הטמעה ויצירת קשר</ActionButton>
          </a>
        </PageCard>
    </div>
  );

}

export default AboutUs;