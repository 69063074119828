import React from 'react';
import { useNavigate } from "react-router-dom";
import './TopBar.css';
import { ReactComponent as Logo } from './images/logo.svg';

function TopBar({ onKebabClick, onClose  }) {

  const navigate = useNavigate();

  return (
    <div className="topBar">
      <div className="iconContainer">
          <img src="/kebab.png" alt="Menu" onClick={onKebabClick} className="kebabIcon" />            
          <img src="/info.png" alt="Info" className="infoIcon"  onClick={() => {navigate('aboutus')}}/>
      </div>
      <a href='/' className='centerLogo'>
        <Logo style={{width:"100%"}}/>
      </a>
      <button onClick={onClose} className="closeButton">
        <img src="/close-details.png" alt="Close" />
      </button>
    </div>
  );
}
  
  export default TopBar;