import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import OvalPrimaryButton from "./Buttons/OvalPrimaryButton";
import "./DetailsComponent.css"; // Ensure this path is correct
import { IronTheme } from "./IronTheme";
import TopBar from "./TopBar";
import useScrollToRef from "./hooks/useScrollToRef";

function DetailsComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.state?.pathname;
  const [showButtons, setShowButtons] = useState(false);
  const [divContentArea, scrollToRef] = useScrollToRef();

  useLayoutEffect(() => {
    scrollToRef();
  }, [location, scrollToRef]);
  
  useEffect(() => {
    // Reset showButtons state whenever the location changes
    setShowButtons(false);
  }, [location]); // Dependency array includes location

  const handleKebabClick = () => {
    console.log("Kebab clicked!");
    setShowButtons(!showButtons); // This will toggle the state of showButtons
  };
  // Function to stop the propagation of click events to prevent onClose from being called when clicking inside the DetailsComponent
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  function sendCloseEventToSDK() {
    // Assuming the SDK is loaded in the parent window
    window.parent.postMessage({ type: 'close' }, '*');
  }

  const closeDetails = () => {
    navigate(path ?? '/');
    sendCloseEventToSDK();
  };

  return (
      <ThemeProvider theme={IronTheme}>
        <div className="detailsOverlay" onClick={closeDetails}>
          <div className="detailsComponent" onClick={stopPropagation}>
            <TopBar onKebabClick={handleKebabClick} onClose={closeDetails} />
            {showButtons && (
              <div
                className={`buttonContainer ${showButtons ? "visible" : ""}`}
              >
                {/* Render PrimaryButton components here */}
                <OvalPrimaryButton
                  text="הסרה"
                  onClick={() => {
                    navigate("optout");
                  }}
                  className="ovalPrimaryButton"
                />
                <OvalPrimaryButton
                  text="דווח על תקלה"
                  className="ovalPrimaryButton"
                  onClick={() => {
                    navigate("contact");
                  }}
                />
                <OvalPrimaryButton
                  text="קצת על היוזמה"
                  onClick={() => {
                    navigate("aboutus");
                  }}
                  className="ovalPrimaryButton"
                />
              </div>
            )}
            <div className="contentArea" ref={divContentArea}>
              {" "}
              {/* Updated class name */}
              <div className={`${showButtons ? "slide" : ""}`}>
                <Outlet />
              </div>
            </div>
            <div className="bottomBar">
              <a onClick={() => navigate("terms")} className="termsText">
                תנאי שימוש ומדיניות פרטיות
              </a>
            </div>
          </div>
        </div>
      </ThemeProvider>
  );
}

export default DetailsComponent;
