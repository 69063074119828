import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './BackButton.css';

function BackButton() {
  const navigate = useNavigate();

  // make the arrow logo smaller
  return (
    <button
      variant="contained"
      onClick={() => navigate(-1)}
      className="backButton"
    >
    <ArrowBackIosNewIcon style={{ fontSize: 15 }} />
    </button>
  );
}

export default BackButton;
