import { TextField } from '@mui/material';
import React, { useState } from 'react';
import useDebounce from './hooks/useDebounce';

function SearchComponent({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useDebounce(() => {
      onSearch(searchTerm);
    },
     300,
    [searchTerm]
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <TextField
        label="חיפוש"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        InputLabelProps={{
          shrink: searchTerm !== '', // Shrink label when there is input
          style: searchTerm === '' ? {
            // Style for non-shrunk state
            textAlign: 'center',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
          } : {
            // Style for shrunk state, it should shrink a bit more than the default
            top: '-8px',
            left: '14px',
          }
        }}
        sx={{
          width: '80%', // Width is 60% of parent
          height: 40, // Adjust the height as needed
          borderRadius: '20px', // Round corners
          "& .MuiOutlinedInput-root": {
            borderRadius: '20px', // Round corners for the input field
            height: '100%', // Ensure input field matches height of TextField
          },
          "& .MuiInputLabel-root": {
            lineHeight: '40px', // Adjust label line height to match TextField height
          }
        }}
      />
    </div>
  );
}

export default SearchComponent;
