import { Link } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import React from 'react';
import { useLoaderData, useLocation, useParams } from "react-router-dom";
import './ContentComponent.css';
import { IronTheme } from './IronTheme';
import PageCard from './PageCard';
import PageHeader from './PageHeader';

async function LoadInitiativeInfo({params}){
  try {
    const initiativeTitle = params.title.trim();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/initiative/${initiativeTitle}`);
    const initiativeData = response.data;
    console.log(initiativeData);
    return initiativeData;
  } catch (error) {
    console.error("Error fetching categories", error);
  }
}

function InitiativeInfo() {
    const { source } = useParams();
    const initiativeData = useLoaderData();
    const location = useLocation();
    const { category, intiativeInfo } = (() => {
      if(source){
        console.log('external'); 
        const category = initiativeData.categories ? initiativeData.categories.split(',')[0] : '';
        return {'category':category, "intiativeInfo": initiativeData}
      }
      console.log('internal');
      return location.state;
    })()

    return (
      <div style={{display:"flex",flexDirection:"column",paddingBottom:"30px"}}>
        <ThemeProvider theme={IronTheme}>
          <PageHeader title={category} />
          <PageCard title={intiativeInfo && intiativeInfo?.title} shareButton>
            {intiativeInfo && intiativeInfo.desc}
            <br />
            {intiativeInfo && intiativeInfo.more_info}
            <br />
            {intiativeInfo && intiativeInfo.location}
            <br />
            <br />
            {intiativeInfo && intiativeInfo.link &&
              <Link href={intiativeInfo.link} target="_blank">לחץ לאתר</Link>
            }
            <br />
            {intiativeInfo && intiativeInfo.email &&
              <Link href={intiativeInfo.email} target="_blank">{intiativeInfo.email.split('mailto:')[1]}</Link>
            }
            <br />
            {intiativeInfo && intiativeInfo.phone}
          </PageCard>
        </ThemeProvider>
      </div>
    );
}

export { InitiativeInfo, LoadInitiativeInfo };
