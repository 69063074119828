import { Card, CardContent, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import ShareButton from './Buttons/ShareButton';
import "./PageCard.css";

export default function PageCard({title, children, shareButton, className}){
    const theme = useTheme();
    return <Card variant="outlined" sx={{ color: theme.palette.secondary.main, position: "relative", fontFamily:"Assistant" }} className={`card ${className}`}>
        {shareButton && <div style={{ position: "absolute", left: "10px", top: "10px" }}>
            <ShareButton />
        </div>}
            <CardContent sx={{ padding: "0px 30px" }}>
                <Typography variant="h5" component="div" className='title' sx={{ paddingBlock: '30px',marginTop:"10px" }} fontWeight={"bold"}>
                    {title}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body1" component="div" >
                    {children}
                </Typography>
            </CardContent>
    </Card>
}
