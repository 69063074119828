const mapCategoryToIcon = {
    "הסברה ושיווק": "🔊",
    "תרבות ומורל": "🎸",
    "לוויות ושבעה": "💔",
    "תמיכה נפשית": "💜",
    "טכנולוגיה (כללי)": "⚙️",
    "טכנולוגיה (סייבר)": "👨‍💻",
    "נעדרים או פצועים": "😞",
    "בריאות ורפואה": "🩸",
    "הסעות ושינוע": "🚚",
    "מזון": "🍔",
    "אירוח ותושבים מפונים": "🏠",
    "חקלאות": " 🍏",
    "סיוע לחיילים": "⚔️",
    "ציוד אזרחי": "📦",
    "ריהוט": "🪑",
    "עסקים": "💼",
    "ילדים ותינוקות": "🍼",
    "בני נוער": "🧑‍🤝‍🧑",
    "הגיל השלישי": "👵",
    "בעלי חיים": "🐶",
    "תרומות": "💰",
    "חינוך": "👩‍🏫",
    "דת": "🕯️",
    "התנדבות": "🤝",
    "משפטי ומימוש זכויות": "⚖️",
    "כללי\אחר": "🧩",
};

export default mapCategoryToIcon;