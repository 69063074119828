import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AboutUs from './AboutUs';
import App from './App';
import CategoriesList from './CategoriesList';
import ContactForm from './ContactForm';
import ContentComponent from './ContentComponent';
import DetailsComponent from './DetailsComponent';
import ErrorPage from "./error-page";
import './index.css';
import {InitiativeInfo, LoadInitiativeInfo} from './InitiativeInfo';
import { cacheRtl, IronTheme } from './IronTheme';
import OptOut from './OptOut';
import reportWebVitals from './reportWebVitals';
import UsagePolicy from './UsagePolicy';

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <DetailsComponent />,
        children: [
          {
            element: <ContentComponent />,
            index: true,
          },
          {
            path: "categories",
            element: <CategoriesList />,
          },
          {
            path: "optout",
            element: <OptOut />,
          },
          {
            path: "categories/initiative/:title",
            element: <InitiativeInfo />,
          },
          {
            path: "aboutus",
            element: <AboutUs />,
          },
          {
            path: "contact",
            element: <ContactForm />,
          },
          {
            path: "terms",
            element: <UsagePolicy />,
          },
          {
            path: "categories/initiative/:title/:source",
            element: <InitiativeInfo />,
            loader: LoadInitiativeInfo
          }
        ],
      },
    ]
  }],{
    basename: "/",
  });

const root = ReactDOM.createRoot(document.getElementById('iron-btn-container'));
root.render(
  <React.StrictMode>
    <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={IronTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
