import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const UseGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!(window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1"))) {
            console.log("initialized GA with: ", process.env.REACT_APP_TRACKING_ID);
            ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            const hitType = "pageview";
            //console.log("reporting to GA",location.pathname );
            ReactGA.send({ hitType , page: location.pathname});
        }
    }, [initialized, location]);
};

export default UseGaTracker;