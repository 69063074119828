// App.js or another higher-level component
import { ThemeProvider } from '@mui/material/styles';
import { React } from 'react';
import { Outlet } from "react-router-dom";
import { IronTheme } from './IronTheme';
import UseGaTracker from './UseGaTracker';
import './shared.css';


function App() {

  UseGaTracker();
  
  return (

      <ThemeProvider theme={IronTheme}>
        <Outlet />
        {/* <BookmarkButton  /> */}
      </ThemeProvider>
  );
}

export default App;
