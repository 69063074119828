import React from 'react';
import BackButton from "./Buttons/BackButton";
import PageCard from './PageCard';

const UsagePolicy = () => {
    return (
        <>
            <div style={{ padding: "20px" }}>
                <BackButton />
            </div>
            <PageCard title='תנאי שימוש ומדיניות פרטיות'>
                
          <p variant="body1" gutterBottom >
          מסמך זה מתאר את תנאי השימוש עבור ״כפתור יחד ננצח״ (״אפליקציה״ או ״שירות״ לסירוגין) וכל הוראותיו מהווים הסכם מחייב בין בעלי האפליקציה / השירות לבין המשתמש או המשתמשת (״אתה״ (יחיד) או ״אתם״ (רבים), מנוסח בלשון זכר אך מיועד לשני המינים).

          </p>

<h2 gutterBottom className='textTitle'>
מי אנחנו?
          </h2>

          <p variant="body1" gutterBottom >
מיזם "כפתור יחד ננצח“ הוא וידג'ט מאובטח שמנגיש באופן פשוט וקל את כל המידע, ההתנדבויות והיוזמות בכפתור אחד מאובטח ופשוט להתקנה בכל אפליקציה ואתר אינטרנט בעולם.
</p>
<p variant="body1" gutterBottom >
המיזם נולד תוך כדי מלחמת "חרבות הברזל". זיהינו שמצד אחד יש המון יוזמות מדהימות שצמחו בן לילה בצורה בתנדבותית ואזרחית לחלוטין ומספקות שירותים אזרחיים חשובים ומדהימים. מצד שני, ריבוי היוזמות, עודף האינפורמציה והעובדה שהן צמחו מהיום למחר, יצרה מציאות בה אנשים כלל לא מכירים ולא נחשפים ליוזמות אלו.
</p>
<p variant="body1" gutterBottom >
הכפתור ניתן להטמעה בצורה פשוטה וקלה בכל אפליקציה ואתר אינטרנט בעולם ומאפשר בדיוק את החשיפה הרחבה ליוזמות מדהימות אלו. אתרים הפועלים כבר תקופה ארוכה וזוכים לחשיפה גבוהה, מאפשרים נגישות גבוהה ותמידית לקהלים.
</p>
<p variant="body1" gutterBottom >
הפתרון שבחרנו משלב טכנולוגיה וחשיפה רחבה, תוך כדי אבטחה מירבית בסיוע של חברת ITway מבית NessCloud ובאמצעות פתרונות Cyber & Security של חברת Akamai, וזאת על מנת לעמוד בתקני אבטחה המחמירים ביותר ולוודא שלא נשקפת שום סכנה בהטמעת הכפתור באתר.
</p>
<p variant="body1" gutterBottom >
על מנת ליצור קשר עם הנהלת האפליקציה / שירות בכל עת ניתן לפנות למנהלי הפרויקט בדואר אלקטרוני: betterclickil@gmail.com, באתר הפרויקט: ironbutton.org או בפניה ישירה לאחד ממיסדי המיזם באזור ”קצת על היוזמה“.
</p>
<h2 gutterBottom className='textTitle'>
אפליקציות / אתרים מארחים (להלן ״מארח״ או ״מארחים״)
</h2>
<p variant="body1" gutterBottom >
כפתור ביחד ננצח מתארח באתרים ובאפליקציות צד ג'. למארחים אלו אין ולא תהיה גישה למידע אותו תבחרו לשתף. המארח אינו צד להתקשרות בין המשתמש לבין השירות / אפליקציה לפי תנאי שימוש אלה, והאפליקציה / שירות אינם פועלים בשם המארח או מטעמו והמארח אינו אחראי על פעילות השירות/אפליקציה או כל ליקוי בה.
</p>
<p variant="body1" gutterBottom >
למארח אין ולא תהיה כל אחריות לשימוש בשירות, לאופי ולטיב השירות שקיבלתם באמצעות השימוש בשירות / באפליקציה ולא תהיה שום תלונה או טענה ביחס למארח שבאתרו בחרתם להשתמש בשירות וזאת לא לגבי אופי או טיב השירות, לרבות פגיעה או מחדל כאלה או אחרים היום או בעתיד.
</p>
<h2 gutterBottom className='textTitle'>
הפניות חיצוניות
</h2>
<p variant="body1" gutterBottom >
כפתור ביחד ננצח הינו פלטפורמה אשר, בין היתר, מציגה קישורים לאתרים חיצוניים לקבלת מידע וסיוע בנושאים רבים ומגוונים שבאים לסייע למשתמשים לקבל מידע אמין וחשוב.
</p>
<p variant="body1" gutterBottom >
יובהר כי השימוש באותם אתרים חיצוניים, בתוכן / במידע / בייעוץ הוא על אחריות המשתמש בלבד ועל דעתו הבלעדית.
</p>
<h2 gutterBottom className='textTitle'>
קבלה
</h2>
<p variant="body1" gutterBottom >
על ידי מילוי הפרטים ושימוש במכשיר שלך, אתה מאשר את תנאי השימוש הללו ומסכים להם. אם אינך מסכים לתנאי השימוש הבאים, אנא הפסק את השימוש וסגור את השירות בלחיצה על כפתור ״סגור״ / ״יציאה / ”איקס““.
</p>
<h2 gutterBottom className='textTitle'>
שירות
</h2>
<p variant="body1" gutterBottom >
האפליקציה / שירות היא כלי טכנולוגי הנועד להעביר מידע שנאסף מהאינטרנט וממקורות רלוונטיים שונים ולהנגיש את קבלתו באופן פשוט, מהיר ורחב. בעלי האפליקציה / שירות אינם יכולים להבטיח לך או למשתמשים האחרים של האפליקציה / שירות את סוג השירות ואיכותו הניתנים על ידי הגורמים המקצועיים אליהם מועברת הפניה. עם זאת, בעלי האפליקציה / שירות עושים ויעשו כמיטב יכולתם על מנת לשפר את איכות השירותים.
</p>
<p variant="body1" gutterBottom >
האפליקציה וכל השירותים הניתנים בקשר אליה ניתנים ”כפי שהם“ וכל האחריות בקשר אליה נדחית בזאת במידה המרבית המותרת על פי החוק.
</p>
<p variant="body1" gutterBottom >
לאחר המעבר ליוזמה חיצונית, אין כל אחריות מצד בעלי האפליקציה / שירות על טיב התוכן ואמינותו. בעלי האפליקציה / שירות אינם יכולים להבטיח לך או למשתמשים האחרים של האפליקציה / שירות את סוג השירות ואיכותו הניתנים על ידי הגורמים החיצוניים אליהם הועברתם. 
</p>
<p variant="body1" gutterBottom >
"בעלי האפליקציה / שירות מעניקים לך את השימוש המוגבל לפי היקף וטריטוריה של האפליקציה / שירות, שהיא זכות בלתי ניתנת להעברה, שאינה ניתנת להקצאה לשימוש באפליקציה / שירות בהתאם לתנאי השימוש. כל העיצובים, התכונות, הגרפיקה הממשקים ורכיבי המולטימדיה, הסימנים המסחריים וסימני השירות נשארים הקניין הרוחני הבלעדי של בעלי האפליקציה.
</p>
<h2 gutterBottom className='textTitle'>
פרטיות
</h2>
<p variant="body1" gutterBottom >
בעלי האפליקציה / שירות מודעים היטב ומכבדים את פרטיותך, עושים ויעשו ככל הניתן על מנת לשמור על פרטיותך ועל פרטיך ומספקים מדיניות פרטיות זו כדי לעזור להבטיח את פרטיותך וכדי לקבל החלטה מושכלת אם ברצונך להשתמש באפליקציה / שירות. אם אינך מסכים למדיניות הפרטיות, אל תשתמש בשירות זה.
</p>
<p variant="body1" gutterBottom >
נכון לעכשיו, בעלי האפליקציה אינם אוספים מידע פרטי ישירות ממך, המידע היחיד שמועבר לשירות הינו המידע שאתה מחליט לשתף. בעלי האפליקציה שומרים לעצמם את הזכות לעשות זאת בהתאם לצרכים עתידיים, תוך הודעה מראש.
</p>
<p variant="body1" gutterBottom >
אתה מודע לכך שצדדים שלישיים, כגון GOOGLE ANALYTICS, עשויים לאסוף מידע סטטיסטי או מידע אחר על השימוש שלך. הבקשה לא תישא באחריות לכל פעולה שננקטה על ידי חברות צד שלישי או אחרות, לגבי איסוף מידע או פעולות אחרות שהן עשויות לנקוט.
</p>
<p variant="body1" gutterBottom >
בעלי האפליקציה / שירות אינם עוקבים אחר תוכן ההודעות והמידע שנשלח. מסד הנתונים והמידע של בעלי האפליקציה מאוחסנים בשרתי צד ג', ולכן בעלי האפליקציה / השירות אינם יכולים להבטיח את ההגנה המלאה של השרת מפני כל פריצה או כל פגיעה אחרת בפרטיותך.
באם נדרש, בעלי האפליקציה / שירות נדרשים לחשוף את כתובת ה IP שלך לרשויות המדינה על פי חוק, כאשר יינתן או יסופק צו.
</p>
<p variant="body1" gutterBottom >
בנוסף, באם ידרשו, הגופים המטפלים יצטרכו לחשוף את היסטוריית ההודעות וכתובת ה IP שלך כאשר יינתן או יסופק צו.
</p>
<p variant="body1" gutterBottom >
בעלי האפליקציה / שירות לא יישאו באחריות מפורשות לפעולות כלשהן שננקטו או שינקטו על ידך ו/או הגורמים המקצועיים בהפרת פרטיות בין אם בכוונה ו/או בשוגג ו/או הוצאת הרשות לרשותך. בעלי האפליקציה / השירות אינם נושאים באחריות לשירותים, לטיפול או לאופן הטיפול בפניות המועברות לגורמים המקצועיים. המידע שנאסף על ידי GOOGLE או APPLE הוא באחריות הבלעדית של GOOGLE או APPLE. 
</p>
<h2 gutterBottom className='textTitle'>
שימוש באפליקציה
</h2>
<p variant="body1" gutterBottom >
כל הזכויות שמורות, אין להעתיק ו/או לשכפל ו/או להפיץ ו/או למסור כל חומר או חלק מהחומר מאפליקציה/שירות זו בכל אמצעי, כמו גם אלה המפורטים במסמך זה, ללא הסכמה מראש ובכתב מאת בעלי האפליקציה/שירות אי פעולה בהתאם עלולה להוות הפרת סימן מסחרי או זכויות יוצרים.אסור בהחלט לפרוץ ו/או לנסות לפרוץ כל חלק ממערכות האפליקציה / שירות ו/או השרתים שלה ו/או מסדי הנתונים שלה ו-
</p>
<p variant="body1" gutterBottom >
1. אסור להעמיס ו/או לשלוח דואר זבל במערכת; < br />
2. אסור לזייף כל ניסיון לגשת לפרוטוקולי התקשורת של המערכת; < br />
3. אינך רשאי לחבל בפונקציונליות של האפליקציה / שירות זו ו/או השרתים שלה ו/או מסדי הנתונים שלה.בעלי האפליקציה/שירות שומרים לעצמם את הזכות לערוך / לשנות מדי פעם את תנאי השימוש של האפליקציה – ללא צורך להודיע ו/או להסביר את החלטותיהם. אנא בקר שוב בדף זה כדי להישאר מעודכן בכל שינוי. בנוסף - < br />
4. על ידי קבלת תנאי שימוש אלה, אתה מסכים שתקרא מדי פעם את תנאי השימוש של היישום; < br />
5. בקבלת תנאי שימוש אלה אתה מסכים לתנאי השימוש גם אם הם ישתנו בעתיד.העותק העדכני ביותר של תנאי השימוש של האפליקציה זמין בדף השירות ובעמוד הפייסבוק שלה. < br />
</p>
<p variant="body1" gutterBottom >
האפליקציה שומרת לעצמה את הזכות לפטר כל משתמש, עקב שימוש מזיק באפליקציה או מכל סיבה אחרת.
</p>
<h2 gutterBottom className='textTitle'>
כתב ויתור על אחריות
</h2>
<p variant="body1" gutterBottom >
השימוש שלך בשירות יהיה על אחריותך הבלעדית. במידה המלאה המותרת על פי חוק, האפליקציה/שירות מתנערת מכל אחריות, מפורשת או משתמעת, בהקשר לשירות ולשימוש שלך בו. בעלי האפליקציה/שירות לא יהיו אחראים במפורש לבעיות כלשהן הנוגעות לאי הדיוק של האפליקציה/שירות. בעלי האפליקציה/שירות יעשו כמיטב יכולתם על מנת האפליקציה/שירות תפעל ללא שגיאות. עם זאת, בעלי האפליקציה/שירות אינם יכולים לשאת באחריות לנזקים, הפסדים או תביעות אחרות הנובעות מטעויות במהלך השימוש באפליקציה.
</p>
<p variant="body1" gutterBottom >
יובהר כי האפליקציה/שירות היא כלי טכנולוגי המתווך בלבד בין פניית המשתמש לבין הגורמים נותני השירות ולא נוטלת שום חלק בטיפול בפניה ולכן בעלי האפליקציה/שירות לא יהיו אחראים במפורש לכל נזק, הפסדים, תביעות, הפרות או דרישות שעלולות להתעורר, על פי כל חוק, חוזה ו/או דוקטרינה משפטית, בהקשר או בקשר לסוגיה כלשהי, כולל, ללא הגבלה, כל עקיף, תוצאתי, דוגמה, עונשי, מיוחד, תיאורטי (כולל אובדן רצון טוב או אובדן רווחים מכל סוג שהוא) או נזק מקרי או אובדן מכל סוג שהוא.
</p>
<p variant="body1" gutterBottom >
לא תוטל שום אחריות משפטית על אופן הטיפול ואיכות הטיפול בפניה או כל קשר ואחריות לפרטי המשתמש
</p>
<p variant="body1" gutterBottom >
בעלי האפליקציה/שירות לא יהיו אחראים במפורש לכל מידע פרטי או ציבורי, בכוונה ו/או בשוגג, שפורסם או שיתפרסם על ידך או על ידך המשתמשים, בין אם כחלק מאיסור אסור אחר. האפליקציה אינה קשורה למידע כלשהו ואינה מפקחת עליו על בסיס יומי ליום.
</p>
<h2 gutterBottom className='textTitle'>
הגבלת אחריות
</h2>
<p variant="body1" gutterBottom >
בשום מקרה, בעלי האפליקציה/שירות, נושאי המשרה, הדירקטורים, העובדים, הסוכנים או כל שותפיה אחרת, לא יהיו אחראים כלפיך לכל נזק עקיף, מקרי, מיוחד, עונשי או תוצאתי הנובע מכך -

1. שגיאות, טעויות או אי דיוקים בתוכן; < br />
2. פציעה אישית או נזק לרכוש, מכל סוג שהוא, הנובע מהגישה שלך לשירות בעלי האפליקציה/שירות והשימוש בו, כל נזק כלכלי או נזק נפשי/מנטלי; < br />
3. כל גישה לא מורשית או שימוש בשרתים מאובטחים של בעלי האפליקציה/שירות ו/או כל מידע אישי או כל מידע אישי; < br />
4. כל הפרעה או הפסקה של שידור אל או מהשרתים של בעלי האפליקציה/שירות; < br />
5. כל באג, וירוס, סוסי טרויאנים או דומים, אשר עשויים להיות מועברים, על ידי כל צד שלישי, לשירות בעלי האפליקציה/שירות או דרך; < br />
6. כל שגיאה או השמטה בכל תוכן או בגין כל אובדן או נזק מכל סוג שהוא שייגרמו כתוצאה מהשימוש שלך בכל תוכן שנעשה זמין דרך צד שלישי אחר (לא מוזכר בתנאי שימוש אלה), עוולה, או כל תיאוריה משפטית אחרת, ובין אם הודיעו לבעלי האפליקציה/שירות על האפשרות של נזקים כאלה ובין אם לאו; < br />
7. חשיפת המידע בהתאם לתנאי השירות או מדיניות הפרטיות הללו. הגבלת החבות האמורה לעיל תחול במידה המלאה המותרת על פי חוק בשטח השיפוט הרלוונטי (על פי המאמר הכללי); < br />
</p>
<p variant="body1" gutterBottom >
אתה מאשר במפורש שבעלי האפליקציה / שירות לא יישא באחריות לפעולות אחרות, משפטיות או אחרות, על ידי צד שלישי כלשהו (מוזכר בתנאי שימוש אלה או לא) וכי הסיכון לפגיעה בפגיעה בך.
</p>
<h2 gutterBottom className='textTitle'>
שיפוי
</h2>

אתה מסכים להגן, לשפות ולחזק את בעלי האפליקציה / שירות ללא פגיעה, מכל וכל תביעה, נזק, התחייבות, הפסדים, התחייבויות, עלויות או חוב, והוצאות (לרבות אך לא רק שכר טרחת עורך דין) הנובעים מכל היבט של שימוש (או שימוש לרעה) וגישה לשירות; או כל הפרה של תנאים והגבלות אלה; או כל תביעת צד שלישי ביחס לאותו.
<h2 gutterBottom className='textTitle'>
אי ויתור
</h2>

בעלי האפליקציה / שירות שומרים על זכותם לבחור באמצעים הדרושים בהם הם בוחרים לפעול לכל הפרה/הפרה של תנאי השימוש וכי בכך אין בעלי האפליקציה / שירות נותנים ויתור מכל סוג שהוא.
<h2 gutterBottom className='textTitle'>
שינויים, תקלות והפסקת שירות
</h2>
<p variant="body1" gutterBottom >
בהתחשב בכל הכתוב במסמך זה, עליך לזכור שבעלי האפליקציה / שירות אינם (ולא יכולים) לשאת באחריות מלאה לזמינות של האפליקציה / שירות זו ו/או לשמירת האפליקציה / שירות פעילה, שכן אפליקציה זו מתארחת אצל שלישי. צד ונתון לסיכונים שאינם תלויים בו (כגון כשלי תקשורת, שגיאות ומחיקות מסד נתונים, פריצות, הפסקות וכו‘).
</p>
<p variant="body1" gutterBottom >
בעלי האפליקציה / שירות שומרים לעצמם את הזכות לשנות את ספק השירות ו/או שירותי האחסון שלהם בכל עת וללא צורך בפרסום עובדה זו או הסבר החלטתם.
</p>
<p variant="body1" gutterBottom >
בעלי האפליקציה / שירות שומרים לעצמם את הזכויות לבצע כל סוג של שינויים במבנה האפליקציה / שירות ו/או האפליקציה, ממשק המשתמש, הגרפיקה, התוכן המוצג, הקצה האחורי, היקפו או הזמינות של השירות בכל עת שירצו בכך וללא צורך במתן הודעה. או להסביר את פעולותיה ו/או החלטותיה. מטבעם של שינויים כאלה - זה עלול לגרום לאובדן שירות זמני, אובדן נתונים, שגיאות ואי נוחות. בקבלת תנאי השימוש אתה מסכים ומצהיר שלא יהיו לך תלונות או תביעות כלשהן לבעלי האפליקציה / שירות בגלל פעולות אלה.
</p>
<p variant="body1" gutterBottom >
בעלי האפליקציה / שירות שומרים לעצמם את הזכות להפסיק את פעולת האפליקציה / שירות ו/או למכור את האפליקציה / שירות ו/או לסגור את פעולת האפליקציה / שירות לחלוטין ו/או להעביר את הבעלות על האפליקציה / שירות לבעלים אחרים בכל עת שירצו, בכפוף לשיקול דעתם הבלעדי. ללא צורך לפרסם או להסביר את החלטותיהם. על ידי קבלת תנאי השימוש אתה מבין שביצוע סעיף זה עלול לגרום לאובדן נתונים.
</p>
<h2 gutterBottom className='textTitle'>
כללי
</h2>
<p variant="body1" gutterBottom >
אתה מסכים שכל התנאים מחייבים תוך התחשבות בכל החוקים והכללים של זכויות היוצרים על פי החוק הישראלי, חוקים אמריקאים וחוקים, אמנות, אמנות והסכמים בינלאומיים החלים אחרים.
</p>
<h2 gutterBottom className='textTitle'>
הינך מסכים ש:
</h2>
<p variant="body1" gutterBottom >
1. השירות ייחשב כבעל בסיס בישראל בלבד וכל סוגיה של מחלוקת לגביו תהיה מנוהלת ויפתר אך ורק בהתאם לחוקי מדינת ישראל. לפיכך, כל שימוש באפליקציה, מחוץ לתחום השיפוט הישראלי הוא באחריותך הבלעדית; < br />
2. לבתי המשפט המוסמכים בתל-אביב, ישראל, תהיה סמכות השיפוט הבלעדית בכל נושא או מחלוקת בקשר אליהם; < br />
3. כל עילת תביעה שלא החלה על ידי צד כלשהו לפי הסכם זה, תתיישן לצמיתות שנה (1) לאחר הצטברותה. < br />
</p>
<h2 gutterBottom className='textTitle'>
תאריך השינוי האחרון: 12-12-23
</h2>
            </PageCard>
        </>
    );
};

export default UsagePolicy;
