
import { createTheme } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';

// Create a theme instance.
export const IronTheme = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: '#00AEEF',
      contrastText: '#fff', // This could be any color that provides good contrast with your primary color
    },
    secondary: {
      main: '#201B7B',
      contrastText: '#fff',
    },
    background: {
      default: '#DEE5FE',
      paper: '#fff', // Assuming you want your cards and other surfaces to be white
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Assistant',
    }
  },
});

export const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});