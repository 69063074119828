import { Grid, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ContentComponent.css"; // Assuming you have a CSS file for this component
import { IronTheme } from "./IronTheme";
import ItemCard from "./ItemCard";
import mapCategoryToIcon from './MapCategoryToIcon';
import Searchbar from './Searchbar';


function ContentComponent() {
  const navigate = useNavigate();
  const [intiativesFull, setIntiativesFull] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(null); // State to hold filtered categories
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch categories from backend
    categoriesFullFetch();
  }, []);


  const handleSearch = (searchTerm) => {
    const categoriesKeys = Object.keys(intiativesFull);
    if (searchTerm) {
      const filtered = categoriesKeys.filter(category =>
        category.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(categoriesKeys); // Reset if search term is empty
    }
  };

  const categoriesFullFetch = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/data`);
      const data = response.data;
      if (data) {
        setIntiativesFull(data);
      }
    } catch (error) {
      console.error("Error fetching categories", error);
    } finally {
    setIsLoading(false); // Stop loading regardless of the outcome
    }
  };

  const showCategoryItems = async (category) => {
    const categoryData = intiativesFull[category];

    // Check if categoryData has items before navigating
    if (categoryData.length === 0) {
      console.warn("No data available for this category");
      return; // Optionally, you could navigate to an error page or show a message
    }

    navigate("categories", {
      state: { category: category, data: categoryData },
    });
  };

  const renderCategoryGridItems = () => {
    const categoriesToShow = filteredCategories || Object.keys(intiativesFull);

    if (categoriesToShow.length === 0) {
      return (
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: 20, // Add some margin at the top for spacing
            fontWeight: 'bold', // Optional: if you want the font to be bold like in contentTitle
            color: '#1F1A78' // Optional: if you want the same color as contentTitle
          }}
        >
          לא נמצאו קטגוריות
        </Typography>
      );
    }

    return categoriesToShow.map((category) => (
      <Grid item key={category} xs={5}>
        <ItemCard
          content={<div className="contentH1">{category.replace('"', "")} ({intiativesFull[category].length})</div>}
          buttonTitle="בחירה"
          onClick={() => showCategoryItems(category)}
          icon={<div className="redCircle flexCenter">{mapCategoryToIcon[category] ? <span style={{fontSize:"24px"}}>{mapCategoryToIcon[category]}</span> : <Icon color="primary">{`${'diversity_1'}`}</Icon>}</div>}
        />
      </Grid>
    ));
  };

  return (
    <div style={{ "height": "100%" }}>
      <ThemeProvider theme={IronTheme}>
        <div className="contentTitle">איך נוכל לעזור לך היום</div>
        <Searchbar onSearch={handleSearch} />
        {isLoading && <div style={{ textAlign: 'center', width: '100%', position: "relative", top: "135px" }}>
          <CircularProgress />
        </div>}
        {!isLoading && <Grid container marginTop={"3px"} spacing={2} className="content" justifyContent="center" columns={{ xs: 12}}>
          {renderCategoryGridItems()}
        </Grid>}
      </ThemeProvider>
    </div>
  );
}

export default ContentComponent;
